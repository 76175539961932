const sizeList = [
  {
    value: 1,
    size: 4.1,
  },
  {
    value: 2,
    size: 4.2,
  },
  {
    value: 3,
    size: 4.3,
  },
  {
    value: 4,
    size: 4.4,
  },
  {
    value: 5,
    size: 4.5,
  },
  {
    value: 6,
    size: 4.6,
  },
  {
    value: 7,
    size: 4.7,
  },
  {
    value: 8,
    size: 4.8,
  },
  {
    value: 9,
    size: 4.9,
  },
  {
    value: 10,
    size: 5.0,
  },
  {
    value: 11,
    size: 5.1,
  },
  {
    value: 12,
    size: 5.2,
  },
  {
    value: 13,
    size: 5.3,
  },
  {
    value: 14,
    size: 5.4,
  },
  {
    value: 15,
    size: 5.5,
  },
  {
    value: 16,
    size: 5.6,
  },
  {
    value: 17,
    size: 5.7,
  },
  {
    value: 18,
    size: 5.8,
  },
  {
    value: 19,
    size: 5.9,
  },
  {
    value: 20,
    size: 6.0,
  },
  {
    value: 21,
    size: 6.1,
  },
  {
    value: 22,
    size: 6.2,
  },
  {
    value: 23,
    size: 6.3,
  },
  {
    value: 24,
    size: 6.4,
  },
  {
    value: 25,
    size: 6.5,
  },
  {
    value: 26,
    size: 6.6,
  },
  {
    value: 27,
    size: 6.7,
  },
  {
    value: 28,
    size: 6.8,
  },
  {
    value: 29,
    size: 6.9,
  },
  {
    value: 30,
    size: 7.0,
  },
  {
    value: 31,
    size: 7.1,
  },
  {
    value: 32,
    size: 7.2,
  },
  {
    value: 33,
    size: 7.3,
  },
  {
    value: 34,
    size: 7.4,
  },
  {
    value: 35,
    size: 7.5,
  },
]

export default sizeList
