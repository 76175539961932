import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import MyAccountUser from 'src/components/icons/MyAccountUser'
import { Skeleton } from '@acctglobal/skeleton'
import { menuAccount } from 'src/utils/menuAccount'
import { useUserContext } from 'src/contexts/user-context'

import storeConfig from '../../../../store.config'

import './styles.scss'

const SizeGuideMenu = () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [load, setLoad] = useState(true)
  const logoutAccount = () => {
    const { account } = storeConfig

    const logoutUrl =
      account === 'lojavivara'
        ? 'https://secure.vivara.com.br/api/vtexid/pub/logout?scope=lojavivara&returnUrl=https://secure.vivara.com.br/'
        : 'https://secureqa.vivara.com.br/api/vtexid/pub/logout?scope=qavivara&returnUrl=https://secureqa.vivara.com.br/'

    window.location.href = logoutUrl
  }

  const { user } = useUserContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        setLoad(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className="sizeguide-menu__container">
      {load ? (
        <div className="skeleton-menu">
          <Skeleton
            width={175}
            height={900}
            borderRadius={4}
            backgroundColor="#F4F4F4"
            padding={10}
          />
        </div>
      ) : (
        <>
          <div className="sizeguide-menu__greeting-container">
            <MyAccountUser />
            {user ? (
              <p className="sizeguide-menu__greeting">
                Olá!{' '}
                {user?.givenName ?? (
                  <span className="sizeguide-menu__greeting-user">
                    {' '}
                    {user?.email}{' '}
                  </span>
                )}
              </p>
            ) : (
              <p className="sizeguide-menu__greeting">Olá!</p>
            )}
          </div>
          <div className="sizeguide-menu__link-container">
            {menuAccount?.map((item) => (
              <a
                href={`https://secure.vivara.com.br/account#/${item.path}`}
                key={item.name}
                className="wishlist-menu__link"
              >
                {item.name}
              </a>
            ))}
            <Link to="/size-guide" className="sizeguide-menu__link-sizeguide">
              Minhas Medidas
            </Link>
            <Link to="/wishlist" className="sizeguide-menu__link">
              Meus Favoritos
            </Link>
            <Link to="/life-lovers" className="sizeguide-menu__link">
              Life Lover
            </Link>
            <button onClick={logoutAccount} className="sizeguide-menu__link">
              Sair
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default SizeGuideMenu
