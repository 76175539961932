import React from 'react'

const plusFull = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.39209 17.0525C0.39209 7.66365 8.00325 0.0524902 17.3921 0.0524902V0.0524902C26.7809 0.0524902 34.3921 7.66365 34.3921 17.0525V17.0525C34.3921 26.4413 26.7809 34.0525 17.3921 34.0525V34.0525C8.00325 34.0525 0.39209 26.4413 0.39209 17.0525V17.0525Z"
      fill="#F08769"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9679 8.37525C17.9679 8.05717 17.7101 7.79932 17.392 7.79932C17.0739 7.79932 16.816 8.05717 16.816 8.37525V16.4766H8.71497C8.39689 16.4766 8.13904 16.7344 8.13904 17.0525C8.13904 17.3706 8.39689 17.6285 8.71497 17.6285H16.816V25.7295C16.816 26.0476 17.0739 26.3055 17.392 26.3055C17.7101 26.3055 17.9679 26.0476 17.9679 25.7295V17.6285H26.0693C26.3873 17.6285 26.6452 17.3706 26.6452 17.0525C26.6452 16.7344 26.3873 16.4766 26.0693 16.4766H17.9679V8.37525Z"
      fill="white"
    />
    <path
      d="M17.3921 33.0525C8.55553 33.0525 1.39209 25.889 1.39209 17.0525H-0.60791C-0.60791 26.9936 7.45096 35.0525 17.3921 35.0525V33.0525ZM33.3921 17.0525C33.3921 25.889 26.2286 33.0525 17.3921 33.0525V35.0525C27.3332 35.0525 35.3921 26.9936 35.3921 17.0525H33.3921ZM17.3921 1.05249C26.2286 1.05249 33.3921 8.21593 33.3921 17.0525H35.3921C35.3921 7.11137 27.3332 -0.94751 17.3921 -0.94751V1.05249ZM17.3921 -0.94751C7.45096 -0.94751 -0.60791 7.11137 -0.60791 17.0525H1.39209C1.39209 8.21593 8.55553 1.05249 17.3921 1.05249V-0.94751Z"
      fill="#FFA67D"
    />
  </svg>
)

export default plusFull
